import * as $ from "jquery";
import {PSO, API, boundsToZoom} from "./_PSO.js";
import GoogleMapsApi from "./GoogleMapsApi";
import {GOOLE_MAP_API_KEY} from "./_variables";
import moment from 'moment';
import {isFreeDay} from "./lib/FreeDays";
import Deposits from "./Deposits";
// import {Storage} from "./Storage";
// import {HistoryStack} from "./lib/HistoryStack";
import {confirm} from "./confirm";
// import {Events} from "./Events";
import {_storage} from "./_storage";
import {_event} from "./_events";
import {RejectionComponent} from "./RejectionComponent";
import {miniPreloader} from "./_mini_preloader";
import Services from "./page_services";

moment.locale('pl');
const clearReservation = () => {
    const $container = $("#car-future-reservation");
    $container.find('.with-reservation').addClass('d-none');
    $container.find('.without-reservation').removeClass('d-none');
};
const fillReservation = reservation => {

    PSO.preloader.stop();
    if (!reservation) return;
    if (reservation.length === 0) return;
    const $container = $("#car-future-reservation");
    $container.find('.with-reservation').removeClass('d-none');
    $container.find('.without-reservation').addClass('d-none');
    const formattedDate = moment(reservation.fromDate)
        .format('dddd, D MMMM YYYY')
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    const fromHour = moment(reservation.fromDate).format('H:mm');
    const toHour = moment(reservation.toDate).format('H:mm');

    if (!reservation.confirmed) {
        $container.find('.dash-appointment-header').text('Rezerwacja oczekuje na potwierdzenie');
    } else {
        $container.find('.dash-appointment-header').removeClass('d-block')
        $container.find('.dash-appointment-header').addClass('d-none');
    }

    let html = `<span class="dash-appointment-header-date">${formattedDate}</span><br>Godzina ${fromHour} do ${toHour}<br><br><b>${reservation.name}</b><br>${reservation.street}<br>${reservation.city}`;
    $container.find('.dash-text-grey').html(html);

    $container.find('#with-rev-data').removeClass('d-none');


    // $container.find('#add-to-calendar').unbind('click').click(function() {
    //     PSO.addReservationToCalendar(reservation)
    //     return false;
    // });
    $container.find('.panel-content').unbind('click').on('click', () => window.location.href = `/#services/code/${reservation.code}`);
    $('#go-to-workshop').attr('target', '_blank').attr('href', PSO.getNavigationLinkFromLatLng(reservation.latitude, reservation.longitude));
    //$('#relocation-button').addClass('disabled').unbind('click').prop('disabled', true);

    Deposits().then(deposits => {
        if (deposits && deposits[0] && deposits[0].workshop) {
            $("#book-details-button").attr('href', `/#services/code/${reservation.code}`);
            if (reservation.code == deposits[0].workshop.code) {
                $('#book').text('SZCZEGÓŁY REZERWACJI');
            } else {
                $('#book').addClass('disabled').unbind('click').prop('disabled', true);
            }
        } else if (reservation) {
            $("#book-details-button").attr('href', `/#services/code/${reservation.code}`);
        } else {
            $("#book-details-button").hide()
        }
    })
}

const renderWorkshop = (workshop, multiple, deposits) => {


    $('#workshop-name-info').html('');
    $('#workshop-street-info').html('');
    $('#workshop-city-info').html('');
    $('#workshop-code-info').html('');

    $('#workshop-name').html('');
    $('#workshop-street').html('');
    $('#workshop-city').html('');
    $('#workshop-code').html('');
    $("#workshop-phone").html('');

    if (multiple) {
        $('#workshop-street').html("Depozyty znajdują się w różnych serwisach. Prosimy o kontakt z BOK");
        $('#workshop-street-info').html("Depozyty znajdują się w różnych serwisach. Prosimy o kontakt z BOK");
        $('#map-overview').hide();
        /// #relocation-button
        $('#book').addClass('disabled').unbind('click').prop('disabled', true);
        $('#car-future-reservation').addClass('d-none');
        return;
    }
    if (!workshop) {
        $('#workshop-name').html("Poza PSO");
        $('#workshop-name-info').html("Poza PSO");
        $('#map-overview').hide();
        return;
    }

    Services.getWorkshops(function () {
        let workshopInfo = PSO.workshops[workshop.code];
        if (workshopInfo.hasOwnProperty('phone')) {
            $("#workshop-phone").html('T: ' + workshopInfo.phone);
        }

        if (workshopInfo.hasOwnProperty('emailForClient')) {
            $("#workshop-email").html(`E: <a href="mailto:${workshopInfo.emailForClient}">${workshopInfo.emailForClient}</a>`);
        }
    });


    workshop.code && $("#workshop-name").parent().unbind('click').click(function () {
        window.location.href = '/#services/code/' + workshop.code
    }).css('cursor', 'pointer');
    $('#workshop-name').html(workshop.nameShort);
    $('#workshop-street').html(workshop.street);
    $('#workshop-city').html(workshop.city);
    $('#workshop-code').html(workshop.code);

    $('#workshop-name-info').html(workshop.nameShort);
    $('#workshop-street-info').html(workshop.street);
    $('#workshop-city-info').html(workshop.city);
    $('#workshop-code-info').html(workshop.code);

    workshop.code && $('#book').removeClass('disabled').unbind('click').prop('disabled', false).click(function () {
        window.location.href = '/#services/code/' + workshop.code
    });

    workshop.code && $('#bookDetails').removeClass('disabled').unbind('click').prop('disabled', false).click(function () {
        window.location.href = '/#services/code/' + workshop.code
    });


    // Services.getWorkshops(function() {
    //     let workshop = PSO.workshops[workshop.code];
    //     if ("nameShort" in workshop) {
    //         $('#book').text("Rezerwacja tel.");
    //     } else {
    //         $('#book').removeClass('disabled').unbind('click').prop('disabled', false).click(function () {
    //             window.location.href = '/calendar.html/' + workshop.id
    //         });
    //     }
    // });

    //$('#go-to-workshop').attr('href', PSO.getNavigationLink(workshop.city + ', ' + workshop.street));
    const map = new GoogleMapsApi(GOOLE_MAP_API_KEY);
    map.load().then(() => {
        Dash.initMap();
        const setMarker = center => {
            Dash.map.setCenter(center);
            Dash.map.setZoom(12);
            new window.google.maps.Marker({
                position: center,
                //title: workshop.nameShort,
                //animation: google.maps.Animation.DROP,
                map: Dash.map,
                icon: (deposits && deposits[0].status === 'depozyt') ? '/build/images/marker_green.png' : '/build/images/marker_blue.png'
            });
        }
        if (workshop.coordinates) {
            let match = workshop.coordinates.match(/^([\d\.]+)[^\d\.]+([\d\.]+)$/)
            if (match) {
                return setMarker(new window.google.maps.LatLng(parseFloat(match[1]), parseFloat(match[2])));
            }
        }
        PSO.geocoding(workshop.city + ', ' + workshop.street, function (response) {
            if (typeof response.results[0] !== 'undefined') {
                let result = response.results[0];
                //let zoom = boundsToZoom(result.geometry.viewport.northeast, result.geometry.viewport.southwest);
                setMarker(result.geometry.location);
            }
        });
    });
}

var Dash = {};
Dash.map = undefined;
Dash.initMap = function () {
    Dash.map = new google.maps.Map(document.getElementById('map-overview'), {
        center: {lat: 52.237, lng: 21.017},
        zoom: 8,
        disableDefaultUI: true
    });
    PSO.grayScaleMap(Dash.map);
};
const addDays = (date, days) => {
    date = moment(date); // use a clone
    while (days > 0) {
        date = date.add(1, 'days');
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7 && !isFreeDay(date)) {
            days -= 1;
        }
    }
    return date;
}
Dash.init = async function () {


    setTimeout(async function () {
        await PSO.getFleet(function () {
            $("#car-fleet-name").html(PSO.fleet.shortName)
        })
    }, 3000);


    await PSO.getFleet(function () {

       // console.log(PSO.fleet);


        //$('.fleet-logo').html(`<img src="/fleet/${PSO.fleet.nip}.png" alt="${PSO.fleet.name}" />`);
    })


    // PSO.getFleet(function () {
    //     //$('.fleet-logo').html(`<img src="/fleet/${PSO.fleet.nip}.png" alt="${PSO.fleet.name}" />`);
    // })


    // PSO.getCar(function () {
    //     $('#car-title').html(PSO.car.description);
    //     $('#car-reg-nr').html(PSO.car.number);
    //     $('#car-vin').html(PSO.car.vin);
    // });


    // Deposits().then(deposits => {
    //
    // });


    // PSO.getDeposits(function() {
    //
    //     if (PSO.deposits.length === 0) {
    //         $('.without-deposits').removeClass('d-none');
    //         $('.with-deposits').addClass('d-none');
    //         return false;
    //     }
    //
    //     $('.without-deposits').addClass('d-none');
    //     $('.with-deposits').removeClass('d-none');
    //
    //     const grouped = PSO.groupDeposit(PSO.deposits);
    //     for (let i in grouped) {
    //         $('#deposit-content-wrapper').append('<li class="dash-text-grey d-block">' + grouped[i].length + ' x ' + grouped[i][0].verbalized + '</li>'); // 2 x 255/35 R19 Continental ContiWinter
    //     }
    //     $('#book').addClass('disabled').unbind('click').prop('disabled', true);
    //     $('#deposit-status').html(PSO.depositStatus(PSO.deposits[0].status));
    //
    //
    //     if (PSO.deposits[0].status === 'poczekalnia' || PSO.deposits[0].status === 'rezerwacja') {
    //         $('#deposit-status').addClass('text-orange').removeClass('text-dark-green');
    //         $('.deposit-in-place').show();
    //         $('.deposit-relocation').hide();
    //         $('img.deposit-in-place').hide();
    //         $('img.deposit-relocation').show();
    //         renderWorkshop(PSO.deposits[0].workshop);
    //     } else if (PSO.deposits[0].status === 'depozyt') {
    //         $('#deposit-status').removeClass('text-orange').addClass('text-dark-green');
    //         $('.deposit-in-place').show();
    //         $('.deposit-relocation').hide();
    //         renderWorkshop(PSO.deposits[0].workshop);
    //     } else  {
    //         $('#deposit-status').addClass('text-orange').removeClass('text-dark-green');
    //         PSO.storage.get('relocations').get().then(relocations => {
    //             $('#deposit-status-comment').html('Planowany termin zakończenia: '
    //                 + addDays(relocations[0].dateAdd, 5)
    //                     .format('dddd, D MMMM YYYY'));
    //             renderWorkshop('workshopTo' in relocations[0] ? relocations[0].workshopTo : false);
    //         });
    //         $('.deposit-in-place').hide();
    //         $('.deposit-relocation').show();
    //     }
    //
    //
    //
    // });

    _storage.getItem('relocationEnabled').then(result => {
        Deposits().then((deposits, relocations) => {
            //PSO.storage.get('relocations').get().then(relocations => {
            if (PSO.depositModel(deposits).multipleServices()) {
                return;
            }
            const inRelocation = deposits && deposits.length && (deposits[0].status == 'relokacja' || (relocations && relocations.length > 0 && relocations[0].deposit && relocations[0].deposit.status && relocations[0].deposit.status == 'relokacja'));
            if (!inRelocation) {
                $('#deposit-status-comment').html('Aby zmienić lokację naciśnij "Relokuj depozyt"')
                if (!result) {
                    $('#relocation-button').removeClass('disabled').prop('disabled', false).unbind('click').click(function () {
                        $("#calendar-confirm-modal2").show();
                    });
                } else if ($('#relocation-button').prop('disabled') !== true) {
                    $('#relocation-button').removeClass('disabled').prop('disabled', false).unbind('click').click(function () {
                        window.location.href = '/#services/relocation';
                        // window.location.href = HistoryStack.buildReturnUrl('services/relocation');
                    });
                }
            }
            //});
        });
        // }
    });


    // Dash.reservation();


    setTimeout(async function () {
        const response = await _storage.getItem('forRating');
        const allowed = await _storage.getItem('forRatingClosed') !== true;
        const rejection = await PSO.getRejection();
        const rejectionsAllowed = await _storage.getItem('forNewTermClosed') !== true;

        if (allowed && response.reservation && (!rejection || !rejectionsAllowed)) {

            const $form = $("#rate_form");
            $form.find('.company').text(`${response.reservation.name}, ${response.reservation.city}`)
            $form.find('.date').text(`${moment(response.reservation.fromDate).format('DD.MM.YYYY H:mm')} - ${moment(response.reservation.toDate).format('H:mm')}`);
            $form.find('form').attr('action', '/rating/' + response.reservation.id)
            $form.removeClass('d-none').show();

            $form.find('.modal-close').unbind('click').click(() => {
                _storage.setTtl('forRatingClosed', 5 * 3600 * 1000);
                _storage.setItem('forRatingClosed', true);
            })
        }

    }, 100);
};
Dash.car = async car => {
    $('[data-car-title]').html(car.description);
    $('[data-car-reg-nr]').html(car.number);
    $('[data-car-vin]').html(car.vin);


}
Dash.rejection = async function () {

    PSO.preloader.stop();
    return false;

    const allowed = await _storage.getItem('forNewTermClosed') !== true;

    if (!allowed) {
        PSO.preloader.stop();
        return false;
    }

    PSO.getRejection().catch(console.warn).then(rejection => {
        if ((rejection !== false) && (typeof rejection === 'object') && (Object.keys(rejection).indexOf('garage') !== -1)) {
            rejection.dates = rejection.dates.filter(date => {
                const time = moment(date);
                return time.diff(moment(), 'hour') > 2;
            })
            if (rejection.dates.length == 0) {
                return;
            }
            const $rContainer = $("#new_term");
            const $rButtons = $rContainer.find('[data-field="date-buttons"]');
            const $template = $($rButtons.data('template'));
            const $confirm_modal = $('#calendar-confirm-modal');
            $rContainer.find('[data-field="garage-name"]').html(rejection.garage.nameShort)
            $rContainer.find('[data-field="garage-address"]').html(`${rejection.garage.street}, ${rejection.garage.city}`)
            $rButtons.children().remove();
            rejection.dates.forEach(date => {
                const $item = $template.clone();
                const time = moment(date);
                $item.html(`${time.format('DD MMMM YYYY [godz.] H:mm')}`);
                $item.data('date', date);
                $rButtons.append($item);
            })
            $rContainer.find('#abort-all-suggested-button').unbind('click').click(function (e) {

                e.preventDefault();
                e.stopPropagation();

                confirm("Na pewno chcesz anulować wszystkie terminy?", () => {
                    PSO.preloader.start();
                    const $container = $("#car-future-reservation");
                    $container.find('.with-reservation').addClass('d-none');
                    $container.find('.without-reservation').removeClass('d-none');
                    _storage.resetItem('rejection');
                    API.asyncPostCall('delete-event-rejection')
                        .then(() => PSO.preloader.stop())
                        .catch(() => PSO.preloader.stop())
                        .finally(() => {
                            $rContainer.addClass('d-none').hide();
                        })
                }, 'btn-danger')
            })
            $rButtons.find('button').unbind('click').click(function (e) {
                e.preventDefault();
                e.stopPropagation();
                const date = $(this).data('date');

                confirm("Potwierdzasz wybór terminu:<br>" + $(this).text() + "?", () => {

                    PSO.preloader.start();
                    API.asyncPostCall('choose-new-date', {
                        token: rejection.token,
                        date: date
                    })
                        .then(() => {
                            PSO.preloader.stop();
                            $confirm_modal.find('#confirm-workshop-name').html(rejection.garage.name);
                            $confirm_modal.find('#confirm-workshop-street').html(rejection.garage.street);
                            $confirm_modal.find('#confirm-workshop-city').html(rejection.garage.city);
                            $confirm_modal.find('#confirm-date').html(moment(date).format('DD MMMM YYYY [o godz.] H:mm'));
                            $confirm_modal.find('#confirm-workshop-offline').removeClass('d-block').addClass('d-none');
                            $confirm_modal.fadeIn();
                            $confirm_modal.find('.confirm-content').hide();
                            $confirm_modal.find('#success').show();
                            $confirm_modal.find('.modal-close').unbind('click').click(function (e) {
                                e.preventDefault();
                                $confirm_modal.fadeOut();
                                return false;
                            });
                            _event.emmit(_event.ON_EVENT_CHANGE);
                            const $container = $("#car-future-reservation");
                            $container.find('.with-reservation').addClass('d-none');
                            $container.find('.without-reservation').removeClass('d-none');
                            Dash.reservation();
                        })
                        .catch(() => {
                            PSO.preloader.stop();
                            $confirm_modal.fadeIn();
                            $confirm_modal.find('.confirm-content').hide();
                            $confirm_modal.find('#error-unknown').show();
                            Dash.reservation();
                        })

                }, 'btn-primary')

            });
            $rContainer.find('.modal-close').unbind('click').click(function () {
                _storage.setTtl('forNewTermClosed', 1 * 3600 * 1000);
                _storage.setItem('forNewTermClosed', true);
            });
            $rContainer.removeClass('d-none').show();
        }
    })
    PSO.preloader.stop()

}
Dash.reservation = async function () {
    PSO.getReservation()
        .then(fillReservation)
        .catch(async () => {

            clearReservation();

            const deposits = await _storage.getItem('deposits', []) || [];
            const relocations = await _storage.getItem('relocations')

            if (!deposits.length) {
                Dash.rejection();
                return;
            }
            if (!deposits[0].workshop || !deposits[0].workshop.code) {
                Dash.rejection();
                return;
            }
            let $src = $("#book-execute-button");
            let $clone = $("#book-execute-button").clone();

            const $container = $("#car-future-reservation");
            if ($container.find('.hyperlink').length == 0) {
                $src.attr('href', '/#services/code/' + deposits[0].workshop.code)
                // PSO.storage.get('relocations').get().then(relocations => {
                if (relocations && relocations.length > 0) {
                    if (relocations[0].workshopTo) {
                        $src.attr('href', '#services/code/' + relocations[0].workshopTo.code)
                    } else {
                        $src.attr('href', '#services')
                    }
                }
                //});
                $clone.text("ZNAJDŹ INNY SERWIS")
                    .removeClass('button')
                    .removeClass('btn')
                    .removeClass('btn-primary')
                    .addClass('text-center')
                    .addClass('hyperlink');
                $clone.insertAfter($src);
            }
            Dash.rejection();
        });

}
Dash.deposits = deposits => {
    deposits = deposits || [];
    if (deposits.length === 0) {
        $('.without-deposits').removeClass('d-none');
        $('.with-deposits').addClass('d-none');
        return false;
    }

    $('.without-deposits').addClass('d-none');
    $('.with-deposits').removeClass('d-none');
    $('#deposit-content-wrapper').children().remove();

    const grouped = PSO.groupDeposit(deposits);
    if (grouped && grouped.length > 0) {
        $('.without-tires').addClass('d-none');
        $('#deposit-content-wrapper').removeClass('d-none');
    }

    for (let i in grouped) {
        $('#deposit-content-wrapper').append('<li class="d-block">' + grouped[i].length + ' x ' + grouped[i][0].verbalized + '</li>'); // 2 x 255/35 R19 Continental ContiWinter
    }
    $('#book').addClass('disabled').unbind('click').prop('disabled', true);
    $('#deposit-status').html(PSO.depositStatus(deposits[0].status));

    if (deposits[0].status === 'poczekalnia' || deposits[0].status === 'rezerwacja' || /*) {
                $('#deposit-status').addClass('text-orange').removeClass('text-dark-green');
                $('.deposit-in-place').show();
                $('.deposit-relocation').hide();
                $('img.deposit-in-place').hide();
                $('img.deposit-relocation').show();
                renderWorkshop(deposits[0].workshop);
            } else if (*/deposits[0].status === 'depozyt') {
        $('#deposit-status').removeClass('text-orange').addClass('text-dark-green');
        $('.deposit-in-place').show();
        $('.deposit-relocation').hide();

        renderWorkshop(deposits[0].workshop, PSO.depositModel(deposits).multipleServices(), deposits);
    } else {
        $('#deposit-status').addClass('text-orange').removeClass('text-dark-green');
        $('#deposit-status-comment').html('');
        _storage.getItem('relocations').then(relocations => {
            if (!relocations || typeof relocations === 'undefined') {
                return;
            }

            $('#deposit-status-comment').html('Planowany termin zakończenia: '
                + addDays(relocations[0].dateAdd, 5)
                    .format('dddd, D MMMM YYYY'));
            renderWorkshop(relocations[0].workshopTo ? relocations[0].workshopTo : false, PSO.depositModel(deposits).multipleServices(), deposits);
        });
        $('.deposit-in-place').hide();
        $('.deposit-relocation').show();
    }
}
Dash.rating = async function () {
    const response = await _storage.getItem('forRating');
    const allowed = await _storage.getItem('forRatingClosed') !== true;
    const rejection = await PSO.getRejection();
    const rejectionsAllowed = await _storage.getItem('forNewTermClosed') !== true;

    if (allowed && response.reservation && (!rejection || !rejectionsAllowed)) {

        const $form = $("#rate_form");
        $form.find('.company').text(`${response.reservation.name}, ${response.reservation.city}`)
        $form.find('.date').text(`${moment(response.reservation.fromDate).format('DD.MM.YYYY H:mm')} - ${moment(response.reservation.toDate).format('H:mm')}`);
        $form.find('form').attr('action', '/rating/' + response.reservation.id)
        $form.removeClass('d-none').show();

        $form.find('.modal-close').unbind('click').click(() => {
            _storage.setTtl('forRatingClosed', 5 * 3600 * 1000);
            _storage.setItem('forRatingClosed', true);
        })
    }
};
Dash.relocation = async function () {

    //$('#relocation-button').addClass('disabled').prop('disabled', false);




    _storage.getItem('relocationEnabled').then(async result => {
        Deposits().then(async (deposits) => {
            const relocations = await _storage.getItem('relocations');
            if (PSO.depositModel(deposits).multipleServices()) {
                return;
            }
            const inRelocation = deposits
                && deposits.length
                && (deposits[0].status == 'relokacja' ||
                    (relocations && relocations.length > 0 && relocations[0].deposit && relocations[0].deposit.status && relocations[0].deposit.status == 'relokacja'));
            if (!inRelocation) {
                $('#deposit-status-comment').html('Aby zmienić lokację naciśnij "Relokuj depozyt"')
                if (!result) {
                    $('#relocation-button').removeClass('disabled').prop('disabled', false).unbind('click').click(function () {
                        $("#calendar-confirm-modal2").show();
                    });
                } else if ($('#relocation-button').prop('disabled') !== true) {
                    $('#relocation-button').removeClass('disabled').prop('disabled', false).unbind('click').click(function () {
                        window.location.href = '/#services/relocation';
                        // _event.emmit(_event.ON_PAGE_CHANGE, {page: 'services'});
                        // window.location.href = HistoryStack.buildReturnUrl('services/relocation');
                    });
                }
            }

            //});
        }).catch(console.error);
        // }
    });
};

Dash.fleetData = async function () {
    setTimeout(async function () {
        await PSO.getFleet(function () {
            $("#car-fleet-name").html(PSO.fleet.shortName)
        })
    }, 3000);
}

_storage.onItemChange('car', Dash.car);
_storage.onItemChange('deposits', async deposits => {

    await Dash.deposits(deposits)
});
_storage.onItemChange('reservation', async reservation => {
    await Dash.reservation()
    miniPreloader.remove('#car-future-reservation');
})
_storage.onItemChange('relocationEnabled', Dash.relocation)
_storage.onItemChange('relocations', Dash.relocation)
_storage.onItemChange('forRating', Dash.rating)

_event.addListener(_event.ON_HIDDEN, async () => {
    miniPreloader.set('#car-future-reservation', 1);
});

_event.addListener(_event.ON_PAGE_CHANGE, async data => {
    if (data.page !== 'dash') return;
    Dash.car(await _storage.getItem('car'));
    Dash.deposits(await _storage.getItem('deposits'));
    Dash.reservation();
    Dash.rating();
    Dash.relocation();
    Dash.fleetData();
});


// if (document.querySelector('[data-dash]')) {
//
//     (async function () {
//         if (!await PSO.isLoggedIn()) {

//             window.location.href = '/login';
//         } else {
//             PSO.preloader.start();
//             Dash.init();
//             Dash.reservation();
//             Dash.car();
//             Dash.deposits();
//         }
//     })();
// };

// Events.listenOnElementSet('reservation', Dash.reservation)
// Events.listenOnElementSet('deposits', Dash.deposits);
// Events.listenOnElementSet('rejection', Dash.reservation);
// Events.listenOnElementSet('car', Dash.car)
// Events.listen(Events.ON_MERCURE_UPDATE, function (e) {
//     _storage.invalidateEventRelated();
//     const $container = $("#car-future-reservation");
//     $container.find('.with-reservation').removeClass('d-none');
//     $container.find('.without-reservation').addClass('d-none');
//     Dash.reservation();
// })
// Events.listen(Events.ON_MERCURE_DELETE, function (e) {
//     Storage.invalidateEventRelated();
//     const $container = $("#car-future-reservation");
//     $container.find('.with-reservation').addClass('d-none');
//     $container.find('.without-reservation').removeClass('d-none');
//     Dash.reservation();
// })
// Events.listen(Events.ON_MERCURE_REJECT, function (e) {
//     Storage.invalidateEventRelated();
//     const $container = $("#car-future-reservation");
//     $container.find('.with-reservation').addClass('d-none');
//     $container.find('.without-reservation').removeClass('d-none');
//     Dash.reservation();
// })
