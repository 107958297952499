import moment from "moment";
import * as $ from "jquery";
import {confirm} from "./confirm";
import {API, PSO} from "./_PSO";
import {_storage} from "./_storage";
import {_event} from "./_events";

export const RejectionComponent = {
    MODAL: 1,
    EMBED: 2,
    mode: 1,
    rejection: null,
    init : async () => {
        $('[data-component="rejection"]').insertAfter($("#new_term_modal"));
        _storage.onItemChange('rejection', async rejection => {
            RejectionComponent.rejection = rejection;
            await RejectionComponent.onRejection();
        });
        _event.addListener(_event.ON_VISIBLE, async () => {
            RejectionComponent.rejection = await _storage.getItem('rejection');
            await RejectionComponent.onRejection();
        })
        _event.addListener(_event.ON_MERCURE_REJECT, async () => {
            _storage.removeItem('rejection');
            _storage.getItem('rejection');
        })
        RejectionComponent.mode = RejectionComponent.MODAL;
        if (document.querySelectorAll('[data-rejection-container]').length > 1) {
            RejectionComponent.mode += RejectionComponent.EMBED;
        }
        RejectionComponent.rejection = await _storage.getItem('rejection');
        if (await _storage.getItem('reservation')) {
            return;
        }
        if (await _storage.getItem('forNewTermClosed')) {
            RejectionComponent.mode = RejectionComponent.mode - RejectionComponent.MODAL;
        }
        if (RejectionComponent.mode === 0) {
            return;
        }
        await RejectionComponent.onRejection();
    },
    showModal: () => {

        $('[data-rejection-container]').addClass('d-none').hide();
        $('[data-rejection-component-modal]').append($('[data-component="rejection"]'));
        $('#new_term_modal').removeClass('d-none').show();
        $('#new_term_modal .modal-close').unbind('click').click(function() {
            $('#new_term_modal').addClass('d-none').hide();
            _storage.setItem('forNewTermClosed', true);
            RejectionComponent.mode = RejectionComponent.EMBED;
            RejectionComponent.showEmbed();
        });
    },
    showEmbed: () => {

        let $item = $('.page-active [data-rejection-component-embed]');
        if ($item.length > 1 || $item.parents($('[data-rejection-container]')).hasClass('template')) {
            $item = $(`.page-active [data-rejection-component-embed=${RejectionComponent.rejection.garage.code}]`);

            if (!$item.length) {
                return setTimeout(RejectionComponent.showEmbed, 1000);
            }
        }
        $item.append($('[data-component="rejection"]'));
        $item.parents($('[data-rejection-container]')).removeClass('d-none').show();
        const $panel = $item.parents('.panel')
        if($panel.length) {
            const $parent = $panel.parent();
            if ($parent.index($panel) > 1) {
                $panel.insertAfter($parent.children(":first"))
            }
        }
    },
    hide: () => {
        $('[data-rejection-container]').addClass('d-none').hide();
        $('[data-rejection-if-not]').removeClass('d-none').show();
    },
    show: () => {

        if (typeof RejectionComponent.rejection.dates === 'undefined') {
            return;
        }
        RejectionComponent.rejection.dates = RejectionComponent.rejection.dates.filter(date => {
            const time = moment(date);
            return time.diff(moment(), 'hour') > 2;
        })
        if (!RejectionComponent.rejection.dates.length) return;
        const that = RejectionComponent;
        const $rContainer = $("[data-component='rejection']");
        const $rButtons = $rContainer.find('[data-rejection-field="date-buttons"]');
        const $template = $($rButtons.data('template'));
        const $confirm_modal = $('#calendar-confirm-modal');
        $('#calendar-confirm-modal').removeClass('offline').removeClass('abort');
        $('[data-rejection-field="garage-name"]').html(RejectionComponent.rejection.garage.nameShort)
        $('[data-rejection-field="garage-address"]').html(`${RejectionComponent.rejection.garage.street}, ${RejectionComponent.rejection.garage.city}`)
        $rButtons.children().remove();
        RejectionComponent.rejection.dates.forEach(date => {
            const $item = $template.clone();
            const time = moment(date);
            $item.html(`${time.format('DD MMMM YYYY [godz.] H:mm')}`);
            $item.data('date', date);
            $rButtons.append($item);
        });
        $('[data-rejection-abort-all]').unbind('click').click(function (e) {
            e.preventDefault();
            e.stopPropagation();
            confirm("Na pewno chcesz anulować wszystkie terminy?", () => {
                PSO.preloader.start();
                API.asyncPostCall('delete-event-rejection')
                    .then(() => {
                        PSO.preloader.stop();
                        _storage.setItem('rejection', false)
                        that.hide();
                    })
                    .catch(() => {
                        PSO.preloader.stop();
                    })
            }, 'btn-danger')
            return false;
        });
        $rButtons.find('button').click(function (e) {
            e.preventDefault();
            e.stopPropagation();
            const date = $(this).data('date');
            confirm("Potwierdzasz wybór terminu:<br>" + $(this).text() + "?" , () => {
                PSO.preloader.start();

                API.asyncPostCall('choose-new-date', {
                    token: that.rejection.token,
                    date: date
                }).then(() => {
                        PSO.preloader.stop();
                        _event.emmit(_event.ON_EVENT_CHANGE);
                        that.hide();
                        try {
                            // $confirm_modal.find('#confirm-workshop-offline').addClass('d-none').removeClass('d-block');
                            // $confirm_modal.find('#confirm-workshop-title').data('content', $confirm_modal.find('#confirm-workshop-title').text());
                            // $confirm_modal.find('#confirm-workshop-subtitle').data('content', $confirm_modal.find('#confirm-workshop-subtitle').text());
                            // $confirm_modal.find('#confirm-workshop-title').text('Potwiedzenie rezerwacji');
                            // $confirm_modal.find('#confirm-workshop-subtitle').text('Potwiedzenie rezerwacji');
                            $confirm_modal.find('#confirm-workshop-name').html(RejectionComponent.rejection.garage.name);
                            $confirm_modal.find('#confirm-workshop-street').html(RejectionComponent.rejection.garage.street);
                            $confirm_modal.find('#confirm-workshop-city').html(RejectionComponent.rejection.garage.city);
                            $confirm_modal.find('#confirm-date').html(moment(date).format('DD MMMM YYYY [o godz.] H:mm'));
                            $confirm_modal.fadeIn();
                            $confirm_modal.find('.confirm-content').hide();
                            $confirm_modal.find('#success').show();
                            $confirm_modal.find('.modal-close').unbind('click').click(function (e) {
                                e.preventDefault();
                                $confirm_modal.fadeOut();
                                $confirm_modal.find('#confirm-workshop-offline').removeClass('d-none').addClass('d-block');
                                return false;
                            });
                        } catch (err) {

                        }
                    })
                    .catch(() => {
                        PSO.preloader.stop();
                        $confirm_modal.fadeIn();
                        $confirm_modal.find('.confirm-content').hide();
                        $confirm_modal.find('#error-unknown').show();
                        that.hide();
                    })

            }, 'btn-primary')

        });
        if (RejectionComponent.mode & RejectionComponent.MODAL) {
            RejectionComponent.showModal();
        } else {
            RejectionComponent.showEmbed();
        }
        $('[data-rejection-if-not]').addClass('d-none').hide();
    },
    onRejection: async () => (RejectionComponent.rejection && (await _storage.getItem('reservation') === false)) ? RejectionComponent.show() : RejectionComponent.hide(),
};
