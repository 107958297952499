import {apiFetch} from "./apiFetch";
import {_storage} from "./_storage";
import {_event} from "./_events";
import {Storage} from "./Storage";

_storage.initItem('token', null, 24 * 3600000000);
_storage.initItem('login', null, 24 * 3600000000);
_storage.initItem('auth', null, 24 * 3600000000)
_storage.initItem('forNewTermClosed', null, 1 * 3600 * 1000);

const logout = async () => {
    await _storage.clear();
    window.location.href = '/logout';
}
const get = async url => {
    try {
        const result  = await apiFetch(url).then(response => {
            if (!response.ok || (response.redirected && response.url.match(/login/))) {
                logout();
            }
            return response.json()
        })

        typeof response === 'object' && 'status' in response && !response.status
            && 'error' in response && response.error === 'User not found' && logout();
        return result;
    } catch {
        await logout();
    }
}
_storage.initItem('reservation', async () => {
    const response = await get('/car-future-reservation')
    await _storage.setItem('hasReservation', response.reservation ? true : false)
    return response.reservation ? response.reservation : false;
}, 300000);
_storage.initItem('rejection', async () => {
    const rejection = await get('/event-rejection')
    return 'garage' in rejection ? rejection : false;
}, 300000);
_storage.initItem('forRating', async () => await get('/get-for-rating'), 300000);
_storage.initItem(
    'fleet',
    async () => await get('/get-fleet')
);
_storage.initItem(
    'relocationEnabled',
    async () => await get('/get-relocation-enabled')
);
// _storage.initItem(
//     'fleet',
//     async () => await get('/get-relocation-enabled')
// );
_storage.initItem(
    'relocations',
    async () => await get('/get-relocations')
);
_storage.initItem(
    'deposits',
    async () => await get('/get-deposits')
);
_storage.initItem(
    'car',
    async () => await get('/get-car')
);
_storage.initItem(
    'systemSettings',
    async () => await get('/get-system-settings')
);
_storage.initItem(
    'workshops',
    async () => await get('/get-workshops')
);
_storage.initItem(
    'car-history',
    async () => await get('/car-history')
);
_storage.initItem(
    'hasReservation',
    async () => await Storage.getItem('reservation') !== false
);

_event.addListener(_event.ON_LOGIN, () => _storage.resetWithCallbacks());
_event.addListener([_event.ON_LOGOUT, _event.ON_CACHE_CLEAR], () => _storage.clear());
_event.addListener([
    _event.ON_MERCURE_DELETE,
    _event.ON_MERCURE_REJECT,
    _event.ON_MERCURE_UPDATE,
    _event.ON_VISIBLE,
    _event.ON_EVENT_CHANGE
], async () => {
    console.info('[EVENT]', arguments);
    if (await _storage.getItem('login')) {
        console.info('[EVENT]', arguments);
        // wait for the reservation update in the backend
        await new Promise(r => setTimeout(r, 2000));
        await _storage.removeItem('reservation');
        await _storage.removeItem('rejection');
        _storage.getItem('reservation');
        _storage.removeItem('forRating');
        _storage.removeItem('car-history');
        _storage.removeItem('hasReservation');
        _storage.removeItem('forNewTermClosed');
        console.info(_storage.getItem('rejection'));
    }
})
_event.addListener([
    _event.ON_MERCURE_REJECT,
    _event.ON_DEPOSIT_CHANGE
], async () => {
    if (await _storage.getItem('login')) {
        await _storage.removeItem('car-history');
        await _storage.removeItem('deposits');
        await _storage.removeItem('relocationEnabled');
        await _storage.removeItem('relocations');

        await _storage.getItem('deposits');
        await _storage.getItem('relocations');
    }
})
_event.addListener(_event.ON_VISIBLE, async () => {
    if (await _storage.getItem('login')) {
        await _storage.removeItem('reservation');
        await _storage.removeItem('rejection');
        await _storage.getItem('reservation');
        await _storage.removeItem('forRating');
        await _storage.removeItem('car-history');
        await _storage.removeItem('hasReservation');
        await _storage.removeItem('forNewTermClosed');
        await _storage.getItem('rejection');
        await _storage.removeItem('deposits');
        await _storage.removeItem('relocationEnabled');
        await _storage.removeItem('relocations');
        await _storage.getItem('deposits');
        await _storage.getItem('relocations');
    }
})
_event.addListener(_event.ON_MERCURE_RELOCATION_END, async () => {
    if (await _storage.getItem('login')) {
        await _storage.removeItem('deposits');
        await _storage.removeItem('relocationEnabled');
        await _storage.removeItem('relocations');
        await _storage.getItem('deposits');
        await _storage.getItem('relocations');
    }
})
